<template>
  <v-container fluid>
    <base-material-card
      icon="fas fa-file-invoice"
      title="Reporte Sell Out"
      class="px-5 py-3"
    >
      <v-row class="mt-3" dense>
        <v-col cols="12" sm="4" lg="3">
          <v-menu
            v-model="menuFechaInicio"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="pagination.fecha_inicial"
                label="Fecha inicial"
                v-on="on"
                readonly
                dense
            ></v-text-field>
            </template>
            <v-date-picker
              elevation-12
              v-model="pagination.fecha_inicial"
              :max="pagination.fecha_final"
              @input="
                menuFechaInicio = false;
                load();
              "
              locale="es-MX"
              class="ma-0"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" sm="4" lg="3">
          <v-menu
            v-model="menuFechaFin"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="pagination.fecha_final"
                label="Fecha fin"
                v-on="on"
                readonly
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="pagination.fecha_final"
              :min="pagination.fecha_inicial"
              @input="
                menuFechaFin = false;
                load();
              "
              locale="es-MX"
              class="ma-0"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" sm="4" lg="3">
          <v-autocomplete
            label="Vendedor"
            v-model="pagination.vendedor_id"
            :items="vendedores"
            item-value="id"
            item-text="razon_social"
            :loading="loading.vendedores"
            auto-select-first
            dense
            @change="load()"
          ></v-autocomplete>
        </v-col>
        
        <v-col cols="12" sm="4" lg="3">
          <custom-select-2
            url="/rutas"
            text="nombre"
            label="Ruta"
            :valor="pagination.ruta_id"
            first-text="Todos"
            dense
            @change="
              pagination.ruta_id = $event;
              load();
            "
          ></custom-select-2>
        </v-col>

        <v-col cols="12">
          <v-btn
            color="primary"
            :loading="loading.pdf"
            @click="downloadPdf()"
            :disabled="0 === productos.length"
          >Descargar PDF</v-btn>
          <v-btn
            color="success"
            @click="downloadExcel()"
            :disabled="0 === productos.length"
          >Descargar Excel</v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-3">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="productos"
            width="100%"
            :loading="loading.table"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:body.append>
              <tr v-if="totales.length != 0">
                <td
                  v-for="(encabezado, i) in headers"
                  :key="i"
                  :align="'nombre' !== encabezado['value'] ? 'center': ''"
                >{{ 'nombre' !== encabezado['value'] ? formatoMoneda(totales[encabezado['value']]) : totales[encabezado['value']] }}</td>
              </tr>
            </template>

            <template v-slot:no-data>
              <span>No hay datos disponibles</span>                
            </template>
          </v-data-table>
        </v-col>
        <v-col col="12">
          <!-- <v-pagination 
            v-model="pagination.page"
            class="my-4"
            :length="pagination.total_page"
            @input="load"
            :total-visible="10"
            color="primary"
          ></v-pagination> -->
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>
import CustomSelect2 from '@views/dashboard/component/v2/CustomSelect.vue';
import _ from 'lodash';
import moment from 'moment';

export default {
  name: 'SellOut',
  components: {
    CustomSelect2,
  },
  data: () => ({
    pagination: {
      fecha_inicial: moment().format('YYYY-MM-DD'),
      fecha_final: moment().format('YYYY-MM-DD'),
      vendedor_id: null,
      ruta_id: null,
      producto_id: [],
      cliente_id: [],
      page: 1,
      last_page: 1,
      total: 0,
      total_page: 0,
    },
    menuFechaInicio: false,
    menuFechaFin: false,
    headers: [],
    productos: [],
    totales: [],
    vendedores: [],
    loading: {
      table: false,
      pdf: false,
      excel: false,
      vendedores: false,
    },
  }),
  mounted() {
    this.obtenerVendedores();
    this.pagination.vendedor_id = 1;
  },
  methods: {
    load: _.debounce(function () {
        this.loading.table = true;

        this.$http
          .get('/reportes/ventas/sellin-sellout/sell-out', {
            params: this.pagination,
          })
          .then((response) => {
            this.headers = response.data.data.encabezados;
            this.productos = response.data.data.productos;
            this.totales = response.data.data.totales;
            // this.pagination.last_page = response.data.last_page;
            // this.pagination.page = response.data.current_page;
            // this.pagination.total = response.data.total;
            // this.pagination.total_page = this.calcularNumeroPaginas(response.data.total, response.data.per_page);
          })
          .finally(() => {
            setTimeout(() => {
              this.loading.table = false;
            }, 1000);
          });
    }, 500),

    formatArray(items) {
      let newArray = [], count = 0;

      for(let i in items) {
        newArray[count++] = items[i];
      };

      return newArray;
    },

    calcularNumeroPaginas(items, perPage) {
      if (items < perPage) {
        return 1;
      }

      let pages = items / perPage;

      if (items%perPage > 0) {
        pages += 1;
      }

      return parseInt(pages);
    },

    downloadPdf() {
      this.loading.pdf = true;

      this.$http({
        url: '/reportes/ventas/sellin-sellout/sell-out/pdf',
        method: 'GET',
        responseType: 'blob',
        params: this.pagination,
      }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'ReporteSellOut.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).finally(() => {
        this.loading.pdf = false;
      });
    },

    downloadExcel() {
      this.loading.excel = true;

      this.$http({
        url: '/reportes/ventas/sellin-sellout/sell-out/excel',
        method: 'GET',
        responseType: 'blob',
        params: this.pagination,
      }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'ReporteSellOut.xls');
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).finally(() => {
        this.loading.excel = false;
      });
    },

    obtenerSucursales(clienteIds) {
      
      if (0 === clienteIds.length) {
        return;
      }
      this.loading.sucursal = true;
   
      this.$http.get('/sucursales/all', {
        params: {
          all: false,
          cliente_id: clienteIds,
          multiple: true,
        }
      }).then(response => {
        this.sucursales = response.data;
      })
      .finally(() => {
        setTimeout(() => {
          this.loading.sucursal = false;
        }, 600);
      });
    },

    obtenerVendedores() {
      this.loading.vendedores = true;

      this.$http
        .get('/vendedores/all')
        .then((response) => {
          this.vendedores = response.data;
        })
        .finally(() => {
          this.loading.vendedores = false;
        });
    },
  },
};
</script>
